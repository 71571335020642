import React from 'react';
import styled from 'styled-components';
import Title from '../../atoms/share/Title'
import ScheduleTable from '../../organisms/management/ScheduleTable'

export interface Props {
  id: number;
}

export const Schedule: React.FC<Props> = ({ id }) => {
  return (
    <ScheduleArea>
      <Title value='出勤表' />
      <ScheduleTable id={id} />
    </ScheduleArea>
  )
};

const ScheduleArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`;

export default Schedule
