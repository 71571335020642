import React, { useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import SubmitButton from '../../../atoms/SubmitButton'
import TextAreaForm from '../../../atoms/share/TextAreaForm'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { years, months, days } from '../../../../modules/date'
import { createHolidayWorkRequest } from '../../../../api/holiday_work_request'

export const HolidayWork = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  const initialFormState = () => {
    setSelectedYear(null)
    setSelectedMonth(null)
    setSelectedDay(null)
    setReason('')
  }

  const handleSubmit = () => {
    setInfo('')
    setError('')
    setLoading(true)
    createHolidayWorkRequest(selectedYear.value, selectedMonth.value, selectedDay.value, reason)
      .then(() => {
        setInfo('申請が完了しました。')
        initialFormState()
      })
      .catch(() => {
        setError('申請内容に不備があります。')
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <HolidayWorkArea>
      <Title value='新規休日出勤申請' />
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <HolidayWorkForm>
        <Item>
          <Label>休日出勤日</Label>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <SelectBox><Select value={selectedDay} onChange={setSelectedDay} options={days} />日</SelectBox>
        </Item>
        <Item>
          <Label>出勤理由</Label>
          <TextAreaBox>
            <TextAreaForm value={reason} onChange={(e) => setReason(e.target.value)} />
          </TextAreaBox>
        </Item>
      </HolidayWorkForm>
      <ButtonBox>
        <SubmitButton
          text="申請する"
          onClick={handleSubmit}
          disabled={!selectedYear || !selectedMonth || !selectedDay || loading}
        />
      </ButtonBox>
    </HolidayWorkArea>
  )
}

const HolidayWorkArea = styled.div`
  margin: 0 0 100px 0;
   font-size: 16px;
`
const HolidayWorkForm = styled.form`
  margin: 12vh 0 6vh;
`
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
`
const SelectBox = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 5px;
`
const TextAreaBox = styled.div`
  width: 420px;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 100px;
  text-align: center;
`

export default HolidayWork
