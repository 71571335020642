import React, { useState } from 'react'
import styled from 'styled-components'
import TextForm from '../../atoms/TextForm'
import SubmitButton from '../../atoms/SubmitButton'
import Error from '../../atoms/share/Error'
import LinkButton from '../../atoms/share/LinkButton'
import { loginCompany } from '../../../api/company'

export const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, serError] = useState("")

  const handleSubmit = () => {
    setLoading(true)
    loginCompany(email, password)
      .then((res) => {
        document.location.href = '/management'
      })
      .catch((res) => {
        serError(res.response.data.message)
        setLoading(false)
      })
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <LoginArea>
      <Top>
        <Title>管理者ログインページ</Title>
        <UserSignIn>
          <LinkButton path={`/users/sign_in`} text='従業員ログインはこちら' />
        </UserSignIn>
      </Top>
      <Warning>{errorMessage()}</Warning>
      <Form>
        <Item>
          <Label>メールアドレス</Label>
          <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Item>
        <Item>
          <Label>パスワード</Label>
          <TextForm type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Item>
        <ButtonBox>
          <SubmitButton
            text="ログイン"
            onClick={handleSubmit}
            disabled={!email || !password || loading}
          />
        </ButtonBox>
      </Form>
    </LoginArea>
  )
}

const LoginArea = styled.div`
  background: #F1F6F9;
  margin: 5vh 200px 4vh;
  padding: 8vh 8vw 16vh;
  font-family: sans-serif,'Noto Sans JP';
  @media screen and (max-width: 1000px) {
    width: 70%;
    height: 650px;
    margin: 0 auto 2rem;
  }
`
const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.h2`
  color: #14274E;
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
  }
`
const UserSignIn = styled.div`
`
const Warning = styled.div`
  text-align: center;
`
const Form = styled.form`
  margin: 76px 100px 0;
  text-align: center;
  @media screen and (max-width: 1000px) {
    margin: 40px auto 0;
    font-size: 1.5rem;
    padding: 3rem 0 0;
  }
`
const Item = styled.div`
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
  @media screen and (max-width: 1000px) {
    width: 35%;
    padding-right: 2rem;
  }
`
const ButtonBox = styled.div`
  text-align: center;
  margin: 75px 0 0 0;
`


export default Login
