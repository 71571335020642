import React from 'react';
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'
import SideLink from '../../molecules/share/SideLink'
import { signOut } from '../../../api/user'

const handleSignOut = () => {
  signOut()
    .then(() => {
      document.location.href = '/'
    })
    .catch((res) => {
      window.alert(res.response.data.message)
    })
}

export const SideList = () => {
  return (
    <SideListArea>
      <Logo />
      <List>
        <SideLink title='打刻' path='/employees' />
        <SideLink title='出勤表' path='/employees/attendance' />
        <SideLink title='打刻修正' path='/employees/modify' />
        <SideLink title='申請' path='/employees/requests' />
        <SideLink title='スタッフ設定' path='/employees/settings' />
        <Logout onClick={handleSignOut}>ログアウト</Logout>
      </List>
    </SideListArea>
  )
}

const SideListArea = styled.div`
  background: #F0F0F0;
  min-height: 800px;
  height: 100%;
  width: 15vw;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: bold;
`
const List = styled.div`
  margin-top: 75px;
`

const Logout = styled.p`
  cursor: pointer;
`

export default SideList
