import React from 'react';
import styled from 'styled-components'
import WorkStatus from '../../organisms/management/WorkStatus'
import StaffList from '../../organisms/management/staff/StaffList'
import Title from '../../atoms/share/Title'

export const Content = () => {
  return (
    <ContentArea>
      <Item>
        <Title value='勤務状況'></Title>
        <WorkStatus />
      </Item>
      <Item>
        <Title value='スタッフ一覧'></Title>
        <StaffList />
      </Item>
    </ContentArea>
  )
}

const ContentArea = styled.section`
  font-family: sans-serif,'Noto Sans JP';
`
const Item = styled.div`
`

export default Content
