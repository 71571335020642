import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import Form from '../../../organisms/management/break_times/Form'

export const Index = () => {
  return (
    <IndexArea>
      <TitleArea>
        <Title value='自動休憩設定' />
        <Form />
      </TitleArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`
const TitleArea = styled.div``

export default Index
