import React from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import ModifyTimestampList from '../../organisms/employees/ModifyTimestampList'
import ModifyTimestampForm from '../../organisms/employees/ModifyTimestampForm'

export const Modify = () => {
  return (
    <ModifyArea>
      <ModifyRequestArea>
        <TitleArea>
          <Title value='打刻修正' />
          <ModifyTimestampForm />
        </TitleArea>
      </ModifyRequestArea>
      {/*<ModifyIndex>
        <ModifyTimestampList />
      </ModifyIndex>*/}
    </ModifyArea>
  )
}

const ModifyArea = styled.div`
  margin: 0 0 100px 0;
`
const ModifyRequestArea = styled.div``
const ModifyIndex = styled.div``
const TitleArea = styled.div``

export default Modify
