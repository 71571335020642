import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import EmploymentStatusForm from '../../../organisms/management/employment_statuses/EmploymentStatusForm'

export interface Props {
  employment_status?: {
    id: number
    name: string
  }
}

export const Edit: React.FC<Props> = ({ employment_status }) => {
  return (
    <EditArea>
      <Title value="スタッフ雇用形態編集" />
      <EmploymentStatusForm employment_status={employment_status} />
    </EditArea>
  )
}

export const EditArea = styled.div``

export default Edit
