import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import HolidayRequestList from '../../../organisms/management/requests/HolidayRequestList'
import OverWorkRequestList from '../../../organisms/management/requests/OverWorkRequestList'
import HolidayWorkRequestList from '../../../organisms/management/requests/HolidayWorkRequestList'

export const Index = () => {
  return (
    <IndexArea>
      <HolidayRequestArea>
        <TitleArea>
          <Title value='休暇申請一覧' />
        </TitleArea>
        <HolidayRequestList />
      </HolidayRequestArea>
      <OverWorkRequestArea>
        <TitleArea>
          <Title value='残業申請一覧' />
        </TitleArea>
        <OverWorkRequestList />
      </OverWorkRequestArea>
      <HolidayWorkRequestArea>
        <TitleArea>
          <Title value='休日出勤申請一覧' />
        </TitleArea>
        <HolidayWorkRequestList />
      </HolidayWorkRequestArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`
const HolidayRequestArea = styled.div`
  margin: 0 0 100px 0;
`
const OverWorkRequestArea = styled.div`
  margin: 0 0 100px 0;
`
const HolidayWorkRequestArea = styled.div`
  margin: 0 0 100px 0;
`
const TitleArea = styled.div``

export default Index
