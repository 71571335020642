import React from 'react';
import styled from 'styled-components';

const ScrollButton = ({ targetRef, label }) => {
  const scrollToTarget = () => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Scroll onClick={scrollToTarget}>
      {label}
    </Scroll>
  );
};

const Scroll = styled.button`
  display: flex;
  color: #ffffff;
  background: #9BA4B4;
  border: 1px solid #d6d3d0;
  font-size: 1rem;
  height: 50px;
  padding: 10px 16px;
  align-items: center;
  width: 100%;
  min-width: 2rem;
  vertical-align: middle;
  border-radius: 30px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  margin: 0px 20px;
  justify-content: space-around;
  letter-spacing: 0.1rem;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
  }
`

export default ScrollButton;
