import React from 'react'
import styled from 'styled-components'

export const Signup = () => {
  return (
    <SignupArea>
      <Top>
        <Title>404 Not found.</Title>
        <Description>
          お探しのページは見つかりませんでした
        </Description>
      </Top>
    </SignupArea>
  )
}

const SignupArea = styled.div`
  background: #F1F6F9;
  margin: 5vh 200px 4vh;
  padding: 8vh 8vw 10vh;
  font-family: sans-serif,'Noto Sans JP';
  @media screen and (max-width: 1000px) {
    width: 65%;
    height: 100%;
    margin: 0 auto 5rem;
  }
`
const Top = styled.div`
  text-align: center;
`
const Title = styled.h2`
  color: #14274E;
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
  }
`
const Description = styled.p`
  font-size: 20px;
  margin: 0;
`
export default Signup
