import React from "react";
import styled from "styled-components";

export interface SecondaryButtonProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  background: #707070;
  border: 1px solid #d6d3d0;
  border-radius: 6px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 5px 15px;
  width: 100%;
  :disabled {
    background: #9BA4B4;
  }
`;

export default SecondaryButton
