import React from 'react'
import styled from 'styled-components'
import summary1 from 'images/home/summary/1.png'
import summary2 from 'images/home/summary/2.png'
import summary3 from 'images/home/summary/3.png'

export const Summary = () => {
  return (
    <SummaryArea>
      <Title>JOB SMARTで解決できること</Title>
      <List>
        <Item>
          <Img1 src={summary1} />
          <TextArea>
            <Subheading>
              従業員の勤怠を一括管理
            </Subheading>
            <Content>
              今までの紙媒体やエクセルなどでの勤怠管理から解放されます。<br />
              JOB SMARTではWEB上で簡単に勤怠を管理することができるので、<br />
              無駄なコストを削減しつつ、勤怠管理を実施することが可能になります。
            </Content>
          </TextArea>
        </Item>
        <Item>
          <Img2 src={summary2} />
          <TextArea>
            <Subheading>
              勤怠データをCSV形式で抽出
            </Subheading>
            <Content>
              各従業員の勤怠データをCSV形式で抽出することが可能です。<br />
              これによりJOB SMART外での管理や資料作成にもご活用いただけます。
            </Content>
          </TextArea>
        </Item>
        <Item>
          <Img3 src={summary3} />
          <TextArea>
            <Subheading>
              アラート機能
            </Subheading>
            <Content>
              管理者側で1ヶ月の残業時間を設定することで、<br />
              残業時間を超えた従業員に対してアラートが出る仕組みになっています。<br />
              これにより従業員の超過労働を抑制することが可能です。
            </Content>
          </TextArea>
        </Item>
      </List>
    </SummaryArea>
  )
}

const SummaryArea = styled.div`
  background: #F1F6F9;
`
const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding: 100px 0 25px 0;
  text-align: center;
  color: #14274E;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
    padding: 3rem 0 25px 0;
  }
`
const List = styled.div`
  margin-top: 50px;
  padding-bottom: 100px;
  @media screen and (max-width: 1000px) {
    margin-top: 1rem;
    padding-bottom: 2rem;
  }
`
const Item = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  @media screen and (max-width: 1000px) {
    padding-bottom: 2rem;
  }
`
const Img1 = styled.img`
  margin: auto 44px;
  width: 180px;
  height: 150px;
  @media screen and (max-width: 1000px) {
    margin: auto 15px;
  }
`
const Img2 = styled.img`
  margin: auto 60px;
  width: 150px;
  height: 135px;
  @media screen and (max-width: 1000px) {
    margin: auto 29px;
  }
`
const Img3 = styled.img`
  margin: auto 44px;
  width: 180px;
  height: 115px;
  @media screen and (max-width: 1000px) {
    margin: auto 15px;
  }
`
const TextArea = styled.div`
  background: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 30px 50px;
  text-align: center;
`
const Subheading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const Content = styled.div`
  font-size: 18px;
`


export default Summary
