import React from 'react'
import styled from 'styled-components'
import TopBanner from 'images/home/top-banner.jpg'

export const Top = () => {
  return (
    <TopArea>
      <Img src={TopBanner} />
      <TextArea>
        <Title>勤怠管理をスマートに、仕事をスマートに。</Title>
        <Content>
          JOB SMARTは必要最低限の機能かつシンプルな勤怠管理サービスです。<br />
          複雑な作業や手間を省くことで仕事のスマート化をお手伝いします。
        </Content>
        <svg xmlns="http://www.w3.org/2000/svg" width="11.833" height="46.876" viewBox="0 0 11.833 46.876">
          <g id="グループ_4" data-name="グループ 4" transform="translate(-934 -1308.955)">
            <path id="パス_78" data-name="パス 78" d="M21.114,38.969a2.451,2.451,0,0,1-4.529,1.3L10,33.682a2.451,2.451,0,1,1,3.468-3.465l2.745,2.746v-18.5a2.451,2.451,0,1,1,4.9,0V38.967Z" transform="translate(924.719 1296.945)" fill="#9ba4b4" />
            <path id="パス_77" data-name="パス 77" d="M19.476,8.441A2.451,2.451,0,0,1,24,7.141l6.587,6.586a2.451,2.451,0,0,1-3.468,3.465l-2.745-2.746v18.5a2.451,2.451,0,1,1-4.9,0V8.441Z" transform="translate(914.524 1320.432)" fill="#14274e" />
          </g>
        </svg>
      </TextArea>
    </TopArea>
  )
}

const TopArea = styled.div`
  margin: 0;
  width: 100%;
`
const Img = styled.img`
  width: 100%;
`
const TextArea = styled.div`
  text-align: center;
  margin-bottom: 100px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 2rem;
  }
`
const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #14274E;
  margin: 100px 0 33px;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
    margin: 2rem 0 1rem;
  }
`
const Content = styled.p`
  font-size: 18px;
  margin: 33px 0 100px;
  @media screen and (max-width: 1000px) {
    font-size: 1rem;
    margin: 1rem 0 1.7rem;
  }
`

export default Top
