import React from 'react'
import styled from 'styled-components'

export interface CheckBoxProps {
  text: string;
  onChange: any;
}

export const TextForm: React.FC<CheckBoxProps> = ({ text, onChange }) => {
  return (
    <Label>
      <Input type='checkbox' value='true' onChange={onChange} />
      {text}
    </Label>
  )
}

const Label = styled.label`
`
const Input = styled.input`
  margin-right: 1rem;
`

export default TextForm
