import React from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import ScheduleTable from '../../organisms/employees/ScheduleTable'

export const Attendance = () => {
  return (
    <AttendanceArea>
      <Title value='出勤表' />
      <ScheduleTable />
    </AttendanceArea>
  )
}

const AttendanceArea = styled.div`
  margin: 0 0 100px 0;
`

export default Attendance
