import React from "react";
import styled from "styled-components";

export interface SubmitButtonProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  border: 1px solid #d6d3d0;
  background: #394867;
  border-radius: 6px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  padding: 5px 15px;
  min-width: 160px;
  font-size: 18px;
  font-weight: bold;
  :disabled {
    background: #9BA4B4;
  }
`;

export default SubmitButton
