import React from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import HolidayRequestList from '../../organisms/employees/requests/HolidayRequestList'
import HolidayWorkRequestList from '../../organisms/employees/requests/HolidayWorkRequestList'
import OverWorkRequestList from '../../organisms/employees/requests/OverWorkRequestList'


export const Requests = () => {
  return (
    <RequestsArea>
      <HolidayRequestArea>
        <TitleArea>
          <Title value='休暇申請' />
        </TitleArea>
        <HolidayRequestList />
      </HolidayRequestArea>
      <OverWorkRequestArea>
        <TitleArea>
          <Title value='残業申請' />
        </TitleArea>
        <OverWorkRequestList />
      </OverWorkRequestArea>
      <HolidayWorkRequestArea>
        <TitleArea>
          <Title value='休日出勤申請' />
        </TitleArea>
        <HolidayWorkRequestList />
      </HolidayWorkRequestArea>
    </RequestsArea>
  )
}

const RequestsArea = styled.div``
const HolidayRequestArea = styled.div`
  margin: 0 0 100px 0;
`
const OverWorkRequestArea = styled.div`
  margin: 0 0 100px 0;
`
const HolidayWorkRequestArea = styled.div`
  margin: 0 0 100px 0;
`
const TitleArea = styled.div``

export default Requests
