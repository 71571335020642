import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import DepartmentForm from '../../../organisms/management/departments/DepartmentForm'

export interface Props {
  department?: {
    id: number
    name: string
  }
}

export const Edit: React.FC<Props> = ({ department }) => {
  return (
    <EditArea>
      <Title value="部署編集" />
      <DepartmentForm department={department} />
    </EditArea>
  )
}

export const EditArea = styled.div`
  font-family: sans-serif , "Noto sans";
`

export default Edit
