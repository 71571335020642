import React from 'react'
import styled from 'styled-components'

export const Footer = () => {
  return (
    <FooterArea>
      <Title>JOB SMART | 勤怠管理をスマートに、仕事をスマートに。</Title>
      <CompanyName>©︎ FUN LLC.</CompanyName>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  display: flex;
  justify-content: space-between;
  background: #9BA4B4;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 20px;
  height: 131px;
  padding: 20px 80px;
  font-family: sans-serif,'Noto Sans JP';
  align-items: center;
`
const Title = styled.h1`
  font-size: 20px;
  margin: 0;
`
const CompanyName = styled.p`
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
`
export default Footer
