import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import SubmitButton from '../../../atoms/SubmitButton'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { fetchDepartments } from '../../../../api/department'
import { fetchEmploymentStatuses } from '../../../../api/employment_status'
import { createWorkingTime } from '../../../../api/working_time'
import { hours, minutes } from '../../../../modules/time'

export const Form = () => {
  type Properties = {
    id: string, name: string
  }
  const [departments, setDepartments] = useState<Properties[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<Properties[]>([]);
  const [department, setDepartment] = useState<Properties>();
  const [employmentStatus, setEmploymentStatus] = useState<Properties>();
  const [fixedHour, setFixedHour] = useState(null)
  const [fixedMinute, setFixedMinute] = useState(null)
  const [overHour, setOverHour] = useState(null)
  const [overMinute, setOverMinute] = useState(null)
  const [unpaidHour, setUnpaidHour] = useState(null)
  const [unpaidMinute, setUnpaidMinute] = useState(null)
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true)
    fetchDepartments()
      .then((res) => { setDepartments(res.data) })
    fetchEmploymentStatuses()
      .then((res) => { setEmploymentStatuses(res.data) })
    setLoading(false)
  }, []);

  const handleSubmit = async() => {
    setInfo('')
    setError('')
    setLoading(true)
    await createWorkingTime(fixedHour?.value, fixedMinute?.value, overHour?.value, overMinute?.value, unpaidHour?.value, unpaidMinute?.value, Number(department?.id), Number(employmentStatus?.id))
      .then(() => {
        setInfo('設定が完了しました。')
      })
      .catch(() => {
        setError('設定に不備があります。')
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <FormArea>
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <FormBody>
        <Item>
          <Label>所属部署</Label>
          <SelectBox>
            <Select
              value={department}
              onChange={setDepartment}
              options={departments}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
            />
          </SelectBox>
        </Item>
        <Item>
          <Label>スタッフ雇用形態</Label>
          <SelectBox>
            <Select
              value={employmentStatus}
              onChange={setEmploymentStatus}
              options={employmentStatuses}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
            />
          </SelectBox>
        </Item>
        <Section>
          <Item>
            <Label><OriginalLabel>{department != undefined ? department.name : '全て'}/{employmentStatus != undefined ? employmentStatus.name : '全て'}の設定</OriginalLabel></Label>
          </Item>
          <Item>
            <Label>所定労働時間</Label>
            <SelectBox>
              <FormText>日計算</FormText>
              <Select value={fixedHour} onChange={setFixedHour} options={hours()} />時間
              <Select value={fixedMinute} onChange={setFixedMinute} options={minutes} />分以下の労働時間を所定労働時間とする。
            </SelectBox>
          </Item>
          <Item>
            <Label>残業時間</Label>
            <SelectBox>
              <FormText>日計算</FormText>
              <SelectFormBox>
                <SelectBox>
                  <Select value={overHour} onChange={setOverHour} options={hours(true)} />時間
                  <Select value={overMinute} onChange={setOverMinute} options={minutes} />分を超える労働を残業時間とする。
                </SelectBox>
                <UnpaidWorkForm>
                  月あたり
                  <Select value={unpaidHour} onChange={setUnpaidHour} options={hours(true)} />時間
                  <Select value={unpaidMinute} onChange={setUnpaidMinute} options={hours(true)} />分はみなし残業とする。
                </UnpaidWorkForm>
              </SelectFormBox>
            </SelectBox>
          </Item>
        </Section>
        <ButtonBox>
          <SubmitButton
            text="保存"
            onClick={handleSubmit}
            disabled={
              loading ||
              !fixedHour ||
              !fixedMinute ||
              !overHour ||
              !overMinute ||
              (!department && !employmentStatus)
            }
          />
        </ButtonBox>
      </FormBody>
    </FormArea>
  )
}

const FormArea = styled.div`
  font-size: 16px;
`
const Notice = styled.div`
  text-align: center;
`
const FormBody = styled.form`
  margin: 12vh 0 100px;
`
const Item = styled.div`
  align-items: center;
  display: flex;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
`
const SelectBox = styled.div`
  display: flex;
  margin: 0 5px;
  align-items: center;
`
const Section = styled.div`
  margin-top: 10vh;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 0;
  text-align: center;
`
const FormText = styled.span`
  margin-right: 20px;
`
const SelectFormBox = styled.div`
`
const UnpaidWorkForm = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: left;
  margin-top: 3vh;
`

const OriginalLabel = styled.div`
  color: #333333;
  font-weight: bold;
  text-align: left;
  padding: 0 0 0 10%;
  margin: 20px 0 10px 0;
`

export default Form