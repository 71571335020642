import React from 'react';
import styled from 'styled-components'

export interface Props {
  companyName: string
  userName: string
}

export const Header: React.FC<Props> = ({ companyName, userName }) => {
  return (
    <HeaderArea>
      <Title>
        スタッフアカウント
      </Title>
      <Name>
        <Company>
          <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="23.943" height="26.438" viewBox="0 0 23.943 26.438">
              <path id="home" d="M13.67,0,1.7,9.3V26.438H9.935V16.095h7.47V26.438h8.236V9.3Z" transform="translate(-1.699)" fill="#14274e" />
            </svg>
          </Icon>
          {companyName}
        </Company>
        <User>
          <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="23.665" height="25.485" viewBox="0 0 23.665 25.485">
              <g id="person" transform="translate(-3.374 -2.25)">
                <path id="パス_172" data-name="パス 172" d="M20.964,4.1A5.858,5.858,0,0,0,16.6,2.25a5.87,5.87,0,0,0-4.369,1.842,6.022,6.022,0,0,0-1.529,4.6c.252,3.476,2.9,6.3,5.9,6.3s5.641-2.828,5.9-6.3A6,6,0,0,0,20.963,4.1Z" transform="translate(-1.397)" fill="#14274e" />
                <path id="パス_173" data-name="パス 173" d="M25.219,31.172H5.2a1.764,1.764,0,0,1-1.377-.633,2.01,2.01,0,0,1-.409-1.656,9.742,9.742,0,0,1,4.334-6.471,13.572,13.572,0,0,1,7.463-2.162,13.572,13.572,0,0,1,7.463,2.162A9.736,9.736,0,0,1,27,28.882a2.011,2.011,0,0,1-.409,1.656A1.764,1.764,0,0,1,25.219,31.172Z" transform="translate(0 -3.437)" fill="#14274e" />
              </g>
            </svg>
          </Icon>
          {userName}
        </User>
      </Name>
    </HeaderArea>
  )
}

const HeaderArea = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 100px;
  align-items: center;
  justify-content: space-between;
`
const Title = styled.div`
  margin: 0;
  padding: 5px 10px;
  background: #14274E;
  color: #ffffff;
  border-radius: 6px;
`
const Name = styled.div`
  display: flex;
  align-items: center;
  margin: 0 120px 0 0;
`
const Company = styled.p`
`
const User = styled.p`
  margin-left: 40px;
`
const Icon = styled.span`
  vertical-align: top;
  margin-right: 12px;
`

export default Header
