import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import Form from '../../../organisms/management/csv_download/Form'

export const Index = () => {
  return (
    <IndexArea>
      <Title value="データダウンロード" />
      <Form />
    </IndexArea>
  )
}

export const IndexArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

export default Index
