import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Modal from 'react-modal'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import SubmitButton from '../../../atoms/SubmitButton'
import SecondaryButton from '../../../atoms/share/SecondaryButton'
import { years, months } from '../../../../modules/date'
import { fetchHolidayRequests, updateHolidayRequest } from '../../../../api/holiday_request'

const heads = ['申請日', '申請者', '申請種類', '休暇期間', '承認状況', '']

export const HolidayRequestList = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [holidayRequests, setHolidayRequests] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    id: null, name: '', date: '', reason: ''
  })
  useEffect(() => {
    fetchHolidayRequests()
      .then((res) => {
        setHolidayRequests(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }, []);

  const handleSubmit = () => {
    fetchHolidayRequests(selectedYear.value, selectedMonth.value)
      .then((res) => {
        setHolidayRequests(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }

  const changeStatus = (id: number, status: number) => {
    updateHolidayRequest(id, status)
      .then((res) => {
        setHolidayRequests(
          holidayRequests.map((holidayRequest) => (
            holidayRequest.id === res.data.id ? res.data : holidayRequest
          ))
        )
        setIsOpen(false)
      }).catch(() => {
        window.alert('承認できませんでした')
      })
  }

  const openModalButton = (id: number, name: string, date: string, status: string, reason: string) => {
    if (status === '未承認') {
      return <SubmitButton text='承認する' onClick={() => {
        setModalContent({ id, name, date, reason })
        setIsOpen(true)
      }} />
    } else {
      return <SecondaryButton text="承認する" onClick={() => setIsOpen(false)} disabled={true} />
    }
  }

  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.85)"
    },
    content: {
      position: "absolute",
      top: "12rem",
      left: "12rem",
      right: "12rem",
      bottom: "12rem",
      backgroundColor: "#fff",
      borderRadius: "1rem",
      padding: "2rem",
      textAlign: "center",
      height: "50%"
    }
  };

  return (
    <HolidayRequestListArea>
      <Top>
        <Section>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <ButtonBox>
            <SubmitButton text='表示する' onClick={handleSubmit} />
          </ButtonBox>
        </Section>
      </Top>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {holidayRequests.map((holidayRequest, index) => (
            <Row key={index}>
              <TableBody>{holidayRequest.created_at}</TableBody>
              <TableBody>{holidayRequest.employee_name}</TableBody>
              <TableBody>{holidayRequest.kind}</TableBody>
              <TableBody>{holidayRequest.date}</TableBody>
              <TableBody>{holidayRequest.status}</TableBody>
              <TableBody>
                {openModalButton(holidayRequest.id, holidayRequest.employee_name, holidayRequest.date, holidayRequest.status, holidayRequest.reason)}
              </TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={modalStyle}>
        <ModalTitle>{modalContent.name}さんから休暇申請が届いています。</ModalTitle>
        <ModalMiddle>
          <ModalItem>
            <ModalLabel>申請内容：</ModalLabel>
            <ModalValue>{modalContent.date}</ModalValue>
          </ModalItem>
          <ModalItem>
            <ModalLabel>申請理由：</ModalLabel>
            <ModalValue>{modalContent.reason}</ModalValue>
          </ModalItem>
        </ModalMiddle>
        <ModalBottom>
          <ModalButtons>
            <ModalButtonsma>
             <SubmitButton text='申請を承認する' onClick={() => changeStatus(modalContent.id, 1)} />
            </ModalButtonsma>
            <ModalButtonsma>
             <SecondaryButton text="申請を却下する" onClick={() => changeStatus(modalContent.id, 2)} />
            </ModalButtonsma>
          </ModalButtons>
          <ModalNotion>※申請を承認すると自動的に反映されます</ModalNotion>
          <ModalNotion>※申請承認後の変更は行えませんのでご注意ください</ModalNotion>
        </ModalBottom>
      </Modal>
    </HolidayRequestListArea>
  )
}

const HolidayRequestListArea = styled.div`
  font-size: 16px;
`
const Top = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 80%;
  margin:0 auto 24px;
`
const Section = styled.div`
  display: flex;
`
const SelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`
const ButtonBox = styled.div`
  margin: 0 0 0 50px;
`
const Table = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr`
`
const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 24px 0 48px;
`
const ModalMiddle = styled.div``
const ModalItem = styled.div`
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
  justify-content: center;
`
const ModalLabel = styled.div`
  color: #707071;
  text-align: center;
`
const ModalValue = styled.div``
const ModalBottom = styled.div`
  margin-top: 48px;
`
const ModalButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
`
const ModalButtonsma =styled.div`
  margin: 0 0 10px 0;
`
const ModalNotion = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: red;
`


export default HolidayRequestList
