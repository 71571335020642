import React from 'react'
import styled from 'styled-components'

export interface TextAreaProps {
  placeholder?: string;
  value: string;
  onChange: any;
}

export const TextAreaForm: React.FC<TextAreaProps> = ({ placeholder = '', value, onChange }) => {
  return (
    <TextArea placeholder={placeholder} value={value} onChange={onChange} />
  )
}

const TextArea = styled.textarea`
  flex-grow: 1;
  display: inline-block;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: calc(24px + 0px);
  padding-right: calc(24px + 0px);
  border: 1px solid #CBCBCB;
  border-radius: 18px;
  font-size: 1rem;
  color: #23221f;
  line-height: 1.6;
  box-sizing: border-box;
  background-color: transparent;
`

export default TextAreaForm
