import React from 'react';
import styled from 'styled-components'
import Signup from '../../../organisms/management/staff/Signup'

export const New = () => {
  return (
    <NewArea>
      <Signup />
    </NewArea>
  )
}

const NewArea = styled.div``;

export default New
