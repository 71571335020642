import React, { ReactNode } from 'react';
import styled from 'styled-components'

export interface Props {
  children: ReactNode
}

export const TableBodyGrid2: React.FC<Props> = ({ children }) => {
  return (
    <BodyArea>{children}</BodyArea>
  )
}

const BodyArea = styled.td`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 0.375rem;
  box-sizing: border-box;
  font-size: 14px;
  padding: 13px 3rem;
  width: 100%;
`

export default TableBodyGrid2
