import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import TableBodyGrid from '../../../atoms/share/TableBodyGrid'
import LinkButton from '../../../atoms/share/LinkButton'
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import { fetchEmployees, deleteEmployee } from '../../../../api/employee'
import LinkButtonDefault from '../../../atoms/share/button/LinkButtonDefault';

export const StaffList = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetchEmployees()
      .then((res) => {
        setEmployees(res.data)
      })
      .catch(() => {
        console.log('通信に失敗しました');
      });
  }, []);

  const heads = ['氏名', '部署', '雇用形態', '月次勤務時間', '残業時間', '超過労働時間', '']

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('スタッフを削除します。よろしいですか？');

    if (confirmed) {
      deleteEmployee(id)
        .then((res) => {
          setEmployees(
            employees.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        });
    }
  }

  return (
    <StaffListArea>
      <ButtonBox>
        <LinkButton text='新規登録' path='/management/staff/new' />
      </ButtonBox>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {employees.map((employee, index) => (
            <Row key={index}>
              <TableBody>{employee.name}</TableBody>
              <TableBody>{employee.department_name}</TableBody>
              <TableBody>{employee.employment_status_name}</TableBody>
              <TableBody>{}</TableBody>
              <TableBody>{}</TableBody>
              <TableBody>{}</TableBody>
              <TableBodyGrid>
                <TableButtonArea>
                  <LinkButtonDefault path={`/management/${employee.id}`} text='詳細' />
                </TableButtonArea>
                <TableButtonArea>
                  <LinkButtonDefault path={`/management/staff/${employee.id}/edit`} text='編集' />
                </TableButtonArea>
                <TableButtonArea>
                  <ButtonRed text='削除' onClick={() => handleDelete(employee.id)} />
                </TableButtonArea>
              </TableBodyGrid>
            </Row>
          ))}
        </Tbody>
      </Table>
    </StaffListArea>
  )
}

const StaffListArea = styled.div`
  margin: 0 0 100px 0;
`
const Table = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
  table-layout: auto;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
  border-bottom: 1px solid black;
`
const Row = styled.tr`
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: right;
  margin-bottom: 30px;
  font-size: 16px;
`
const TableButtonArea = styled.span`
  display: block;
  text-align: center;
`

export default StaffList
