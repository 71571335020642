import React from 'react';
import styled from 'styled-components'
import EditForm from '../../../organisms/management/staff/EditForm'
import {EditableEmployeeParams} from "../../../../types/employee";

interface EditProps {
  staff: EditableEmployeeParams;
}

export const Edit = (props: EditProps) => {
  return (
    <EditArea>
      <EditForm staff={props.staff} />
    </EditArea>
  )
}

const EditArea = styled.div``;

export default Edit
