import React from 'react'
import styled from 'styled-components'

export interface RadioButtonProps {
  text: string;
  name: string;
  value: string
  onChange: any;
}

export const TextForm: React.FC<RadioButtonProps> = ({ text, name, value, onChange }) => {
  return (
    <Label>
      <Input type='radio' name={name} value={value} onChange={onChange} />
      {text}
    </Label>
  )
}

const Label = styled.label`
`
const Input = styled.input`
  margin-right: 1rem;
`

export default TextForm
