import React from 'react'
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'

export const Header = () => {
  return (
    <HeaderArea>
      <Logo />
    </HeaderArea>
  )
}

const HeaderArea = styled.header`
  box-sizing: border-box;
  height: 116px;
  padding: 20px 80px;
  font-family: sans-serif, 'Noto Sans JP';
  display: flex;
  align-items: center;
`

export default Header
