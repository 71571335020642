import React from 'react'
import styled from 'styled-components'
// import { PrimaryButton } from 'smarthr-ui'
// <PrimaryButton>Hello World</PrimaryButton>

export const Footer = () => {
  return (
    <FooterArea>
      <Top>
        <Title>JOB SMART<TitleInner>|</TitleInner>勤怠管理をスマートに、仕事をスマートに。</Title>
      </Top>
      <MiddleContent>
        運営会社
        <Middle href='https://www.fun-lcc.com/' target={'_blank'} rel='no-referrer'>
          FUN合同会社
        </Middle>
      </MiddleContent>
      <Bottom>
        プライバシー | 利用規約
      </Bottom>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  background: #9BA4B4;
  color: #FFFFFF;
  font-size: 20px;
  padding: 20px 40px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    padding: 1px 40px;
  }
`
const Top = styled.div`
`
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 50px 30px 30px;
`
const TitleInner = styled.span`
  margin: 0 10px;
`
const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  margin: 25px 0 50px 0;
`
const Middle = styled.a`
  display: block;
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
`
const Bottom = styled.div`
  display: none;
  font-size: 18px;
`


export default Footer
