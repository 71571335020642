import React from 'react';
import styled from 'styled-components'

export interface Props {
  path: string;
  text: string;
}

export const LinkButton: React.FC<Props> = ({ path, text }) => {
  return <ButtonArea href={path}>{text}</ButtonArea>
}

const ButtonArea = styled.a`
  background: #14274E;
  border: 1px solid #d6d3d0;
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  padding: 5px 15px;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;
`

export default LinkButton
