import React, { useRef } from 'react'
import styled from 'styled-components'
import Header from '../organisms/home/Header'
import Top from '../organisms/home/Top'
import Summary from '../organisms/home/Summary'
import Feature from '../organisms/home/Feature'
import Plan from '../organisms/home/Plan'
import Contact from '../organisms/home/Contact'
import Footer from '../organisms/home/Footer'

export const Home = () => {
  const contactFormRef = useRef(null);

  return (
    <HomeArea>
      <Header contactFormRef={contactFormRef} />
      <Top />
      <Summary />
      <Feature />
      <Plan />
      <Contact contactFormRef={contactFormRef} />
      <Footer />
    </HomeArea>
  )
}

const HomeArea = styled.div`
  font-family: sans-serif, 'Noto Sans JP';
`

export default Home
