import React from 'react'
import styled from 'styled-components'

export const Contact = ({ contactFormRef }) => {
  return (
    <ContactArea ref={contactFormRef}>
      <Title>お問い合わせ</Title>
      <Discription>ご要望、導入相談等ぜひお問い合わせください</Discription>
      <Title>info@fun-lcc.com</Title>
    </ContactArea>
  )
}

const ContactArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5rem auto;
  width: 60%;
  height: fit-content;
  padding: 2rem 0;
  background-color: #F1F6F9;
  @media screen and (max-width: 1000px) {
  }
`
const Title = styled.h1`
  margin: 0 auto;
`
const Discription = styled.h4`
  margin: 1rem auto;
`

export default Contact
