import React from 'react';
import styled from 'styled-components'
import Timestamp from '../../organisms/employees/Timestamp'

export const Index: React.FC = () => {
  return (
    <IndexArea>
      <Timestamp />
    </IndexArea>
  )
}

const IndexArea = styled.div`
  margin: 0 0 100px 0;
`

export default Index
