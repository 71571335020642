import React from 'react'
import styled from 'styled-components'

const Plan = () => {
  return (
    <PlanArea>
      <Title>料金プラン</Title>
      <PlanTable>
        <tr>
          <Head></Head>
          <OneMonthPriceHead>1ヶ月プラン</OneMonthPriceHead>
          <SixMonthPriceHead>6ヶ月プラン</SixMonthPriceHead>
          <YearPriceHead>年間プラン<YearPlanBox></YearPlanBox></YearPriceHead>
        </tr>
        <tr>
          <TopBoby>価格</TopBoby>
          <OneMonthPriceBody>30万円</OneMonthPriceBody>
          <SixMonthPriceBody>150万円</SixMonthPriceBody>
          <YearPriceBody>240万円</YearPriceBody>
        </tr>
        <tr>
          <TopBoby>アカウント数</TopBoby>
          <Body>無制限</Body>
          <Body>無制限</Body>
          <YearBody>無制限</YearBody>
        </tr>
        <tr>
          <TopBoby>CSVデータ抽出</TopBoby>
          <Body>◎</Body>
          <Body>◎</Body>
          <YearBody>◎</YearBody>
        </tr>
        <tr>
          <TopBoby>各種申請</TopBoby>
          <Body>◎</Body>
          <Body>◎</Body>
          <YearBody>◎</YearBody>
        </tr>
        <tr>
          <TopBoby>アラート機能</TopBoby>
          <Body>◎</Body>
          <Body>◎</Body>
          <YearBody>◎</YearBody>
        </tr>
        <tr>
          <TopBoby>オプション</TopBoby>
          <Body>別途追加可</Body>
          <Body>別途追加可</Body>
          <YearBody>別途追加可</YearBody>
        </tr>
      </PlanTable>
      <Description>
        ※上記プランは税抜価格です<br />
        ※当社サービスは自動更新となっています
      </Description>
      <Options>
        <Title>オプション</Title>
        <List>
          <Item>
            <ItemText>
              導入サポート<br />
              <br />
              50万円〜
            </ItemText>
          </Item>
          <Item>
            <ItemText>
              コンサルティング<br />
              <br />
              40万円〜
            </ItemText>
          </Item>
          <Item>
            <ItemText>
              社内運用マニュアル作成<br />
              <br />
              30万円〜
            </ItemText>
          </Item>
        </List>
        <Description>
          ※上記は税抜価格です
        </Description>
      </Options>
    </PlanArea>
  )
}

const PlanArea = styled.div`
  background: #F1F6F9;
  padding: 50px 150px;
  margin: 100px 0;
  @media screen and (max-width: 1000px) {
    padding: 1rem 0;
    margin: 2rem 0 2.5rem;
  }
`
const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #14274E;
  text-align: center;
  margin: 50px 0 75px;
  @media screen and (max-width: 1000px) {
    font-size: 2rem;
    margin: 2rem 0 1rem;
  }
`
const PlanTable = styled.table`
  margin: 0 auto;
  width: 80%;
  border-collapse: separate;
  border-spacing: 1em 3em;
  text-align: center;
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`
const Head = styled.th`
  color: #fff;
  padding: 20px 30px;
  @media screen and (max-width: 1000px) {
    padding: 20px 35px;
  }
`
const OneMonthPriceHead = styled(Head)`
  background: #9BA4B4;
  font-size: 20px;
  width: auto;
  height: auto;
`
const SixMonthPriceHead = styled(Head)`
  background: #394867;
  font-size: 20px;
  width: auto;
  height: auto;
`
const YearPriceHead = styled(Head)`
  background: #6C63FF;
  font-size: 24px;
  width: 240px;
  position: relative;
  @media screen and (max-width: 1000px) {
    width: 150px;
  }
`
const YearPlanBox = styled.div`
  border: 3px solid #6C63FF;
  width: 295px;
  height: 750px;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 1000px) {
    width: 216px;
  }
`
const Body = styled.td`
  padding: 15px;
`
const TopBoby = styled(Body)`
  color: #707071;
  font-size: 18px;
  text-align: left;
`
const OneMonthPriceBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const SixMonthPriceBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const YearPriceBody = styled(Body)`
  font-size: 30px;
  font-weight: bold;
`
const YearBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const Description = styled.p`
  color: #707071;
  font-size: 12px;
  margin: 10px 0 0;
  padding-left: 10rem;
  display: none;
`
const Options = styled.div`
  margin-top: 80px;
  @media screen and (max-width: 1000px) {
    margin-top: 20px;
  }
`
const List = styled.div`
  display: flex;
  justify-content: space-around;
`
const Item = styled.div`
  background: #fff;
  display: table;
  text-align: center;
  width: 280px;
  height: 145px;
  border-radius: 30px;
`
const ItemText = styled.p`
  display: table-cell;
  font-weight: bold;
  vertical-align: middle;
`

export default Plan
