import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import TextForm from '../../../atoms/TextForm'
import SubmitButton from '../../../atoms/SubmitButton'
import { createEmployee } from '../../../../api/employee'
import { fetchDepartments } from '../../../../api/department'
import { fetchEmploymentStatuses } from '../../../../api/employment_status'

export const Signup = () => {
  type Properties = {
    id: string, name: string
  }
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [completeSignup, setCompleteSignup] = useState(false)
  const [department, setDepartment] = useState<Properties>();
  const [departments, setDepartments] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState<Properties>();
  const [employmentStatuses, setEmploymentStatuses] = useState([]);


  useEffect(() => {
    fetchDepartments()
      .then((res) => {
        setDepartments(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
    fetchEmploymentStatuses()
      .then((res) => {
        setEmploymentStatuses(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }, []);

  const handleSubmit = () => {
    setLoading(true)
    createEmployee(lastName, firstName, email, password, Number(department?.id), Number(employmentStatus?.id))
      .then(() => {
        initialForm()
        setLoading(false)
        setCompleteSignup(true)
      })
      .catch(() => {
        setLoading(false)
        console.log('通信に失敗しました')
        window.alert('通信に失敗しました')
      })
  }

  const initialForm = () => {
    setLastName("")
    setFirstName("")
    setEmail("")
    setPassword("")
  }

  const noticeComplete = () => {
    if (completeSignup) {
      return (
        <p>登録が完了しました</p>
      )
    }
  }

  return (
    <SignupArea>
      <Title value="スタッフ新規登録" />
      <Form>
        <Notification>
          {noticeComplete()}
        </Notification>
        <Item>
          <Label>姓</Label>
          <TextForm type="text" placeholder="山田" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </Item>
        <Item>
          <Label>名</Label>
          <TextForm type="text" placeholder="太郎" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </Item>
        <Item>
          <Label>部署</Label>
          <SelectBox>
            <Select
              value={department}
              onChange={setDepartment}
              options={departments}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
            />
          </SelectBox>
        </Item>
        <Item>
          <Label>スタッフ雇用形態</Label>
          <SelectBox>
            <Select
              value={employmentStatus}
              onChange={setEmploymentStatus}
              options={employmentStatuses}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
            />
          </SelectBox>
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Item>
        <Item>
          <Label>仮パスワード</Label>
          <TextForm type="password" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Item>
        <RegistrationButton>
          <SubmitButton
            text="新規登録する"
            onClick={handleSubmit}
            disabled={loading || !lastName || !firstName || !email || !password}
          />
        </RegistrationButton>
      </Form>
    </SignupArea>
  )
}

const SignupArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

const Form = styled.form`
  margin: 76px 100px 0;
  font-size: 16px;
`
const Notification = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #06d6a0;
`
const Item = styled.div`
  align-items: center;
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
`
const SelectBox = styled.div`
  display: flex;
  margin: 0 5px;
  align-items: center;
`
const RegistrationButton = styled.div`
  width: 100%;
  text-align: center;
  margin: 75px 0 0 0;
`
export default Signup
