import React, { useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import SubmitButton from '../../../atoms/SubmitButton'
import TextAreaForm from '../../../atoms/share/TextAreaForm'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { years, months, days } from '../../../../modules/date'
import { createHolidayRequest } from '../../../../api/holiday_request'

const kinds = [
  { value: 0, label: '有給' },
  { value: 1, label: '代休' }, 
  { value: 2, label: '振替休日'}
]

export const Holiday = () => {
  const [kind, setKind] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  const initialFormState = () => {
    setKind('')
    setSelectedYear(null)
    setSelectedMonth(null)
    setSelectedDay(null)
    setReason('')
  }

  const handleSubmit = () => {
    setInfo('')
    setError('')
    setLoading(true)
    createHolidayRequest(Number(kind.value), selectedYear.value, selectedMonth.value, selectedDay.value, reason)
      .then(() => {
        setInfo('申請が完了しました。')
        initialFormState()
      })
      .catch(() => {
        setError('申請内容に不備があります。')
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <HolidayArea>
      <Title value='新規休暇申請' />
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <HolidayForm>
        <Item>
          <Label>休暇名</Label>
          <SelectBox><Select value={kind} onChange={setKind} options={kinds} /></SelectBox>
        </Item>
        <Item>
          <Label>休暇希望日</Label>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <SelectBox><Select value={selectedDay} onChange={setSelectedDay} options={days} />日</SelectBox>
        </Item>
        <Item>
          <Label>休暇理由</Label>
          <TextAreaBox>
            <TextAreaForm value={reason} onChange={(e) => setReason(e.target.value)} />
          </TextAreaBox>
        </Item>
      </HolidayForm>
      <ButtonBox>
        <SubmitButton
          text="申請する"
          onClick={handleSubmit}
          disabled={!kind || !selectedYear || !selectedMonth || !selectedDay || loading}
        />
      </ButtonBox>
    </HolidayArea>
  )
}

const HolidayArea = styled.div`
  margin: 0 0 100px 0;
  font-size: 16px;
`
const HolidayForm = styled.form`
  margin: 12vh 0 6vh;
`
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
`
const SelectBox = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 5px;
`
const TextAreaBox = styled.div`
  width: 430px;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 100px;
  text-align: center;
`

export default Holiday
