import client from './client'

// 下記でいけるのでは
// https://qiita.com/shate/items/bd52e1a09c30f1bff4ca

// export interface createCompanyParams {
//   name: string,
//   staffName: string,
//   email: string,
//   cell: string,
//   password: string,
//   plan: number,
//   hasOption: boolean
// }

export const fetchCompanies = () => {
  return client.get('/api/v1/companies')
}

// ここpropsにinterface渡せるか確認
export const createCompany = (name: string, staff_name: string, email: string, cell: string, password: string, plan: number, has_option: boolean) => {
  return client.post(
    '/api/v1/companies',
    {
      name: name,
      staff_name: staff_name,
      email: email,
      cell: cell,
      password: password,
      plan: plan,
      has_option: has_option
    }
  )
}

export const loginCompany = (email: string, password: string) => {
  return client.post('/companies/sign_in', { company: { email: email, password: password } })
}

export const signOutCompany = () => {
  return client.delete('/companies/sign_out')
}

interface updateCompanyParams {
  name: string;
  staff_name: string;
  cell?: string;
  email: string;
  password: string;
  plan?: number;
  has_option?: boolean;
}

export const updateCompany = (id: number, params: updateCompanyParams) => {
  return client.put(`/api/v1/companies/${id}`, params)
}

export const deleteCompany = (id: number) => {
  return client.delete(`/api/v1/companies/${id}`)
}
