import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import TextForm from '../../../atoms/TextForm'
import SubmitButton from '../../../atoms/SubmitButton'
import {updateCompany} from '../../../../api/company'
import { EditableCompanyParams } from "../../../../types/company";

interface EditFormProps {
  company: EditableCompanyParams;
}

export const EditForm = (props: EditFormProps) => {
  type Properties = {
    id: string, name: string
  }
  const [name, setName] = useState(props.company.name)
  const [staffName, setStaffName] = useState(props.company.staff_name)
  const [cell, setCell] = useState(props.company.cell)
  const [email, setEmail] = useState(props.company.email)
  const [password, setPassword] = useState("")
  const [plan, setPlan] = useState(props.company.plan)
  const [hasOption, setHasOption] = useState(props.company.has_option)
  const [loading, setLoading] = useState(false)
  const [updateComplete, setUpdateComplete] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    updateCompany(props.company.id, { name: name, staff_name: staffName, cell: cell, email: email, plan: plan, password: password, has_option: hasOption})
      .then(() => {
        setLoading(false)
        setUpdateComplete(true)
      })
      .catch(() => {
        setLoading(false)
        console.log('通信に失敗しました')
        window.alert('通信に失敗しました')
      })
  }

  const noticeComplete = () => {
    if (updateComplete) {
      return (
        <p>更新が完了しました</p>
      )
    }
  }

  return (
    <EditFormArea>
      <Title value="管理者アカウント編集" />
      <Form>
        <Notification>
          {noticeComplete()}
        </Notification>
        <Item>
          <Label>事業者名</Label>
          <TextForm type="text" placeholder="株式会社JOBSMART" value={name} onChange={(e) => setName(e.target.value)} />
        </Item>
        <Item>
          <Label>代表者名</Label>
          <TextForm type="text" placeholder="山田太郎" value={staffName} onChange={(e) => setStaffName(e.target.value)} />
        </Item>
        <Item>
          <Label>電話番号</Label>
          <TextForm type="tel" placeholder="01234567890" value={cell} onChange={(e) => setCell(e.target.value)} />
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Item>
        <Item>
          <Label>パスワード</Label>
          <TextForm type="password" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Item>
        <RegistrationButton>
          <SubmitButton
            text="更新する"
            onClick={handleSubmit}
            disabled={loading || !name || !staffName || !cell || !email}
          />
        </RegistrationButton>
      </Form>
    </EditFormArea>
  )
}

const EditFormArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

const Form = styled.form`
  margin: 76px 100px 0;
  font-size: 16px;
`
const Notification = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #06d6a0;
`
const Item = styled.div`
  align-items: center;
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
`
const SelectBox = styled.div`
  display: flex;
  margin: 0 5px;
  align-items: center;
`
const RegistrationButton = styled.div`
  width: 100%;
  text-align: center;
  margin: 75px 0 0 0;
`
export default EditForm
