import React from 'react';
import styled from 'styled-components'

export interface HeaderProps {
  companyName: string;
}

export const Header: React.FC<HeaderProps> = ({ companyName }) => {
  return (
    <HeaderArea>
      <Title>
        管理者アカウント
      </Title>
      <Company>
        <Icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="23.943" height="26.438" viewBox="0 0 23.943 26.438">
            <path id="home" d="M13.67,0,1.7,9.3V26.438H9.935V16.095h7.47V26.438h8.236V9.3Z" transform="translate(-1.699)" fill="#14274e" />
          </svg>
        </Icon>
        {companyName}
      </Company>
    </HeaderArea>
  )
}

const HeaderArea = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 100px;
  justify-content: space-between;
  font-family: sans-serif,'Noto Sans JP';
`
const Title = styled.div`
  margin: 0;
  padding: 5px 10px;
  background: #14274E;
  color: #ffffff;
  border-radius: 6px;
`
const Company = styled.p`
  margin: 0 120px 0 0;
  display: flex;
  align-items: center;
`
const Icon = styled.span`
  vertical-align: top;
  margin-right: 12px;
`

export default Header
